import * as React from "react"
import { list } from './list.module.scss'

/******************************
 * Component
 ******************************/

export function UL (props) {  
  return (
    <ul {...props} className={[list, props.className].join(' ')} />
  )
}

export function OL (props) {  
  return (
    <ol {...props} className={[list, props.className].join(' ')} />
  )
}