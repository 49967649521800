import React from 'react'
import { headline, headlineSmall } from './headline.module.scss'

/******************************
 * Component
 ******************************/

export const H1 = (props) => <h1 className={['font-xxl', headline, props.className].join(' ')} {...props} />
export const H2 = (props) => <h2 className={['font-xl', headline, props.className].join(' ')} {...props} />
export const H3 = (props) => <h3 className={['font-l', headline, props.className].join(' ')} {...props} />
export const H4 = (props) => <h4 className={['font-s', headline, headlineSmall, props.className].join(' ')} {...props} />
export const H5 = (props) => <h5 className={['font-s', headline, headlineSmall, props.className].join(' ')} {...props} />
