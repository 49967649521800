import * as React from "react"

import { UL } from '../components/list'
// import { P } from '../components/paragraph'
import { H2, H3 } from '../components/headline'

// markup
const IndexPage = () => {
  return (
    <React.Fragment>
      <H3 className='spacer-m'>Figma Plugins</H3>

      <UL className='spacer-m'>
        <li><a href='https://www.figma.com/community/plugin/732240841094697441/Viewports' target='_blank'>Viewports</a></li>
        <li><a href='https://www.figma.com/community/plugin/891651591932556735/Sweeper---design-linter' target='_blank'>Sweeper</a></li>
      </UL>

      <H3 className='spacer-m'>Figma Templates</H3>

      <UL className='spacer-m'>
        <li><a href='https://www.figma.com/community/file/768726574016795759' target='_blank'>iOS Keyboards</a></li>
        <li><a href='https://www.figma.com/community/file/813360898159990062' target='_blank'>iOS App Icon</a></li>
        <li><a href='https://www.figma.com/community/file/831970552583056395' target='_blank'>iOS Status Bar</a></li>
        <li><a href='https://www.figma.com/community/file/1020059937577706844' target='_blank'>iOS Tab Bar</a></li>
        <li><a href='https://www.figma.com/community/file/961331431332098758' target='_blank'>iOS Popovers</a></li>
        <li><a href='https://www.figma.com/community/file/917471841966099807' target='_blank'>iOS Notifications</a></li>   
      </UL>
    </React.Fragment>
  )
}

export default IndexPage
